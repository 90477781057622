import styled from 'styled-components'
import { mediaMax } from '../../../utils/breakpoints'

export const Title = styled.h1`
  font-weight: 300;
  color:#fff;
  z-index:1;
  width: 70vw;
  font-size:min(max(24px, 4.167vw), 64px);
  font-family: ${({theme}) => theme.fonts.fontFamilyPrimary};
  line-height:1.2;

  @media ${mediaMax.tablet}{
    padding-top:50px;
    width:80%;
  }
`

export const Abstract = styled.p`
  color:${({theme}) => theme.colors.white};
  font-family: ${({theme}) => theme.fonts.fontFamilySecondary};

	font-size: min(max(16px, 1.45833vw), 24px);
  line-height: 148%;

  margin-top:40px;
  width: 47vw;

  @media ${mediaMax.tablet}{
    width: 100%;
  }
`

export const Description = styled.p`
  color:${({theme}) => theme.colors.white};
  font-family: ${({theme}) => theme.fonts.fontFamilySecondary};

  font-size:min(max(18px, 1.25vw), 22px);
	line-height: 148%;
`